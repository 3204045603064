/** ***********************************************************************************
 * Supermatita common styles
 *********************************************************************************** */

body {
  --sm-c-page--LineHeight: 1.31;

  --sm-c-page--Font: var(--sm-global--Font-dynamic-md-medium);
  --sm-c-page--md--Font: var(--sm-global--Font-dynamic-lg-medium);

  --sm-c-page--PaddingBlockEnd: 3.5rem;  /* 56px */
  --sm-c-page--md--PaddingBlockEnd: var(--sm-c-page--PaddingBlockStart);

  --sm-c-page--PaddingBlockStart: 2.25rem;  /* 87px / 2 */
  --sm-c-page--md--PaddingBlockStart: 3.75rem;  /* 61px */

  --sm-c-page--PaddingInlineEnd: 2.75rem;  /* 43px */
  --sm-c-page--md--PaddingInlineEnd: 0rem;

  --sm-c-page--PaddingInlineStart: 2.75rem;  /* 43px */
  --sm-c-page--md--PaddingInlineStart: 0rem;

  --sm-c-page--tn--contentwidth: max-content;
  --sm-c-page--sm-md--contentwidth: 68vw;
  --sm-c-page--sm-2xl--contentwidth: 61.2rem;  /* 981px */
  --sm-c-page--contentwidth: var(--sm-c-page--tn--contentwidth);

  --sm-c-page--GridTemplateColumns:
    minmax(var(--sm-c-page--PaddingInlineStart), 1fr)
    [content-start]
    var(--sm-c-page--contentwidth)
    [content-end]
    minmax(var(--sm-c-page--PaddingInlineEnd), 1fr);
  --sm-c-page--md--GridTemplateColumns:
    minmax(var(--sm-c-page--PaddingInlineStart), 1fr)
    [gills-start]
    repeat(calc(var(--sm-global--gill-count) - 1), var(--sm-global--gill-size) var(--sm-global--gill-gap))
    var(--sm-global--gill-size)
    [content-start]
    var(--sm-c-page--contentwidth)
    [content-end]
    repeat(calc(var(--sm-global--gill-count) - 1), var(--sm-global--gill-size) var(--sm-global--gill-gap))
    var(--sm-global--gill-size)
    [gills-end]
    minmax(var(--sm-c-page--PaddingInlineEnd), 1fr);

  --sm-c-page--GridTemplateRows:
    var(--sm-c-page--PaddingBlockStart)
    2fr
    [content-start]
    auto
    [content-end]
    1fr
    var(--sm-c-page--PaddingBlockEnd);
  --sm-c-page--sm--GridTemplateRows:
    var(--sm-c-page--PaddingBlockStart)
    5fr
    [content-start]
    auto
    [content-end]
    4fr
    var(--sm-c-page--PaddingBlockEnd);
  --sm-c-page--md--GridTemplateRows:
    var(--sm-c-page--PaddingBlockStart)
    [content-start]
    1fr
    [content-end]
    var(--sm-c-page--PaddingBlockEnd);


  /**
   * Default color palette
   */

  --sm-c-page--AccentColor: var(--sm-global--palette-green);
  --sm-c-page--BackgroundColor: var(--sm-global--palette-indigo);
  --sm-c-page--Color: var(--sm-global--palette-white);


  /**
   * Scrollbars
   */

  --sm-c-page__scrollbar-track--size: .2rem;

  --sm-c-page__scrollbar-track--BackgroundColor: var(--sm-global--palette-indigo);
  --sm-c-page__scrollbar-thumb--BackgroundColor: var(--sm-global--palette-white);


  /**
   * Main content
   */

  --sm-c-page__main--GridAutoColumns: max-content;
  --sm-c-page__main--md--GridAutoColumns: 1fr;

  --sm-c-page__main--GridAutoFlow: column;
  --sm-c-page__main--md--GridAutoFlow: row;

  --sm-c-page__main--MarginBlockStart: 0;
  --sm-c-page__main--md--MarginBlockStart: 8rem;  /* 126px */


  /**
   * Text content
   */

  --sm-c-page__text--MarginBlock: .1em;  /* eyeballed */
}

/** Block: small */
@media all and (min-height: 31.25rem) {
  body {
    --sm-c-page--GridTemplateRows: var(--sm-c-page--sm--GridTemplateRows);
  }

  /** Inline: medium */
  @media all and (min-width: 48rem) {
    body {
      --sm-c-page--contentwidth: var(--sm-c-page--sm-md--contentwidth);
      --sm-c-page--Font: var(--sm-c-page--md--Font);
      --sm-c-page--GridTemplateColumns: var(--sm-c-page--md--GridTemplateColumns);
      --sm-c-page--GridTemplateRows: var(--sm-c-page--md--GridTemplateRows);
      --sm-c-page--PaddingBlockEnd: var(--sm-c-page--md--PaddingBlockEnd);
      --sm-c-page--PaddingBlockStart: var(--sm-c-page--md--PaddingBlockStart);
      --sm-c-page--PaddingInlineEnd: var(--sm-c-page--md--PaddingInlineEnd);
      --sm-c-page--PaddingInlineStart: var(--sm-c-page--md--PaddingInlineStart);
      --sm-c-page__main--GridAutoColumns: var(--sm-c-page__main--md--GridAutoColumns);
      --sm-c-page__main--GridAutoFlow: var(--sm-c-page__main--md--GridAutoFlow);
      --sm-c-page__main--MarginBlockStart: var(--sm-c-page__main--md--MarginBlockStart);
    }
  }

  /** Inline: extra extralarge */
  @media all and (min-width: 90rem) {
    body {
      --sm-c-page--contentwidth: var(--sm-c-page--sm-2xl--contentwidth);
    }
  }
}



/**
 * Page
 * 1: reset
 * 2: disable rubberband scrolling and pull to refresh
 * 3: line-height must come after font
 */

html,
body {
  margin: 0;  /* 1 */
  padding: 0;  /* 1 */
}

html {
  height: 100%;
  overscroll-behavior: none;  /* 2 */
}

body {
  accent-color: var(--sm-c-page--AccentColor);
  background-color: var(--sm-c-page--BackgroundColor);
  color: var(--sm-c-page--Color);
  display: grid;
  font: var(--sm-c-page--Font); line-height: var(--sm-c-page--LineHeight);  /* 3 */
  grid-template-columns: var(--sm-c-page--GridTemplateColumns);
  grid-template-rows: var(--sm-c-page--GridTemplateRows);
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

/** Block: Small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  body {
    overflow-x: hidden;
    overflow-y: auto;
  }
}



/**
 * Scrollbars
 * 1: this is just for gecko
 */

:root {
  scrollbar-color: var(--sm-global--palette-white) transparent;  /* 1 */
  scrollbar-gutter: stable;
  scrollbar-width: thin;  /* 1 */
}

body::-webkit-scrollbar {
  background-color: var(--sm-c-page__scrollbar-track--BackgroundColor);
  width: var(--sm-c-page__scrollbar-track--size);
  height: var(--sm-c-page__scrollbar-track--size);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--sm-c-page__scrollbar-thumb--BackgroundColor);
  border-radius: 100vmax;
  font: var(--sm-c-page--Font);
  width: .8rem;
}



/**
 * Canvas
 * 1: behind main content (1)
 * 2: cover the whole viewport
 * 3: all pointer events should go to canvas
 */

body > .sm-c-canvas {
  grid-column: 1 / -1;  /* 2 */
  grid-row: 1 / -1;  /* 2 */
  height: 100vh; height: 100dvh;  /* 2 */
  width: 100%;  /* 2 */
  z-index: 0;  /* 1 */
}

body > .sm-c-canvas ~ * {
  pointer-events: none;  /* 3 */
  touch-events: none;  /* 3 */
}

/** Block: Small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  body > .sm-c-canvas {
    height: 100%;
    width: 100vw;  /* 2 */
  }
}



/**
 * Main content
 * 1: start a stacking context
 * 2: on top of drawing board (0)
 * 3: center content
 * 4: fix content alignment on Safari
 */

main {
  align-content: center;
  align-self: start;  /* 4 */
  box-sizing: border-box;
  display: grid;
  grid-area: content;
  grid-auto-columns: var(--sm-c-page__main--GridAutoColumns);
  grid-auto-flow: var(--sm-c-page__main--GridAutoFlow);
  grid-column-gap: 1ch;
  justify-content: center;  /* 3 */
  justify-self: center;  /* 3 */
  margin-block-start: var(--sm-c-page__main--MarginBlockStart);
  position: relative;  /* 1 */
  z-index: 1;  /* 2 */
}

main > * {
  margin: 0;
}

@supports not (margin-block-start: 0) {
  main {
    margin-top: var(--sm-c-page__main--MarginBlockStart);
  }
}



/**
 * Text content
 */

main > p {
  margin-block: var(--sm-c-page__text--MarginBlock);
}

@supports not (margin-block: var(--sm-c-page__text--MarginBlock)) {
  main > p {
    margin-bottom: var(--sm-c-page__text--MarginBlock);
    margin-top: var(--sm-c-page__text--MarginBlock);
  }
}



/**
 * Page header
 * 1: on top of drawing board canvas (0)
 */

body > .sm-c-page-header,
body > .sm-c-page-header > * {
  z-index: 1;  /* 1 */
}



/**
 * Dialog
 * 1: disable buttons in a closed dialog
 */

dialog:not([open]) button {
  pointer-events: none;  /* 1 */
  touch-events: none;  /* 1 */
}



/**
 * dialog-polyfill
 * see dialog-polyfill/dialog-polyfill.css
 */

dialog + .backdrop {
  background: rgba(0,0,0,0.1);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

._dialog_overlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}



/**
 * Linebreaks
 */

main br {
  display: none;
}

/** Block: small / Inline: medium */
@media all and (min-height: 31.25rem) and (min-width: 48rem) {
  main br {
    display: initial;
  }
}



/**
 * Active elements
 */

:not(.sm-m-drawing) :is(a, button, label) {
  pointer-events: auto;
  touch-events: auto;
}
