/** ***********************************************************************************
 * Color design tokens
 *********************************************************************************** */

:root {
  /**
   * Color palette
   */

  /** White: #fff */
  --sm-global--palette-white--100: rgba(255, 255, 255, 1);
  --sm-global--palette-white--65: rgba(255, 255, 255, .65);
  --sm-global--palette-white: var(--sm-global--palette-white--100);

  /** Oil rush: #342d41 */
  --sm-global--palette-gray--100: rgba(52, 45, 65, 1);
  --sm-global--palette-gray--50: rgba(52, 45, 65, .5);
  --sm-global--palette-gray: var(--sm-global--palette-gray--100);

  /** Electric indigo: #6400fc */
  --sm-global--palette-indigo--100: rgba(100, 0, 252, 1);
  --sm-global--palette-indigo--80: rgba(100, 0, 252, .8);
  --sm-global--palette-indigo: var(--sm-global--palette-indigo--100);

  /** Lifeless green: #00e5ab */
  --sm-global--palette-green--100: rgba(0, 229, 171, 1);
  --sm-global--palette-green--90: rgba(0, 229, 171, .9);
  --sm-global--palette-green--40: rgba(0, 229, 171, .4);
  --sm-global--palette-green: var(--sm-global--palette-green--100);

  /** Lighthouse: #f4f4f4 */
  --sm-global--palette-fog--100: rgba(244, 244, 244, 1);
  --sm-global--palette-fog--25: rgba(244, 244, 244, .25);
  --sm-global--palette-fog: var(--sm-global--palette-gray--100);
}
