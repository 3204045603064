/** ***********************************************************************************
 * Supermatita miscellaneous design tokens
 *********************************************************************************** */

@keyframes sm-global-animationName--pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.08);
  }
}

@keyframes sm-global-AnimationName--draw {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}

:root {
  /**
   * Durations
   */

  --sm-global--Duration--tn: 100ms;
  --sm-global--Duration--xs: 150ms;
  --sm-global--Duration--sm: 300ms;
  --sm-global--Duration--md: 700ms;
  --sm-global--Duration--lg: 1000ms;
  --sm-global--Duration--xl: 3000ms;


  /**
   * Timing functions
   */

  --sm-global--AnimationTimingFunction--bounce: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --sm-global--AnimationTimingFunction--easeoutcubic: cubic-bezier(0.33, 1, 0.68, 1);


  /**
   * Animations
   */

  --sm-global--Animation--pulse:
    var(--sm-global--Duration--md)
    ease-in
    infinite
    alternate
    sm-global-animationName--pulse;
}
