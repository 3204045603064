/** ***********************************************************************************
 * Supermatita layout design tokens
 *********************************************************************************** */

:root {
  /**
   * Block breakpoints
   */

  --sm-global--breakpoints--block-sm: 31.25rem;  /** Small: 500px */
  --sm-global--breakpoints--block-md: 37.5rem;   /** Medium: 600px */


  /**
   * Inline breakpoints
   */

  --sm-global--breakpoints--inline-sm: 23.4375rem;  /** Small: 375px */
  --sm-global--breakpoints--inline-md: 48rem;       /** Medium: 768px */
  --sm-global--breakpoints--inline-lg: 64rem;       /** Large: 1024px */
  --sm-global--breakpoints--inline-xl: 80rem;       /** Extralarge: 1280px */
  --sm-global--breakpoints--inline-2xl: 90rem;      /** Extra extralarge: 1440px */
  --sm-global--breakpoints--inline-3xl: 120rem;     /** Extra extra extralarge: 1920px */


  /**
   * Layout
   */

  --sm-global--gill-count: 5;  /* must be more than 0 */

  --sm-global--gill-gap: 0;
  --sm-global--md--gill-gap: max(.69rem, min(1.33vw, 1.25rem));  /* clamp(.69rem, 1.33vw, 1.25rem) */ /* 10.76px / 20px */

  --sm-global--gill-size: 0;
  --sm-global--md--gill-size: max(1rem, min(2.11vw, 1.875rem));  /* clamp(1rem, 2.11vw, 1.875rem) */ /* 16.5px / 30px */
}

/** Inline: medium */
@media all and (min-width: 48rem) {
  :root {
    --sm-global--gill-gap: var(--sm-global--md--gill-gap);
    --sm-global--gill-size: var(--sm-global--md--gill-size);
  }
}
