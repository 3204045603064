/** ***********************************************************************************
 * Supermatita typography design tokens
 *********************************************************************************** */

@font-face {
  font-family: "EuclidCircularB";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Light-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Light-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-LightItalic-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-LightItalic-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Regular-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Regular-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-RegularItalic-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-RegularItalic-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Medium-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Medium-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-MediumItalic-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-MediumItalic-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Semibold-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Semibold-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-SemiboldItalic-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-SemiboldItalic-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Bold-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-Bold-WebS.woff") format("woff");
}

@font-face {
  font-family: "EuclidCircularB";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("~/assets/fonts/euclid-circular-b/EuclidCircularB-BoldItalic-WebS.woff2") format("woff2"),
       url("~/assets/fonts/euclid-circular-b/EuclidCircularB-BoldItalic-WebS.woff") format("woff");
}

@font-face {
  font-family: "cutehandwriting";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("~/assets/fonts/cutehandwriting/cutehandwriting-webfont.woff2") format("woff2"),
       url("~/assets/fonts/cutehandwriting/cutehandwriting-webfont.woff") format("woff");
}

:root {
  /**
   * Font families
   */

  --sm-global--FontFamily-default: "EuclidCircularB", sans-serif;
  --sm-global--FontFamily-special: "cutehandwriting", sans-serif;


  /**
   * Font weights
   */

  --sm-global--FontWeight-thin:       100;
  --sm-global--FontWeight-extralight: 200;
  --sm-global--FontWeight-light:      300;
  --sm-global--FontWeight-normal:     400;
  --sm-global--FontWeight-medium:     500;
  --sm-global--FontWeight-semibold:   600;
  --sm-global--FontWeight-bold:       700;
  --sm-global--FontWeight-extrabold:  800;
  --sm-global--FontWeight-black:      900;
  --sm-global--FontWeight-heavy:      var(--sm-global--FontWeight-black);
  --sm-global--FontWeight-regular:    var(--sm-global--FontWeight-normal);


  /**
   * Font sizes
   */

  --sm-global--FontSize-title--sm:     1.5rem;    /* Small: 24px */
  --sm-global--FontSize-title--md:     1.625rem;  /* Medium: 26px */
  --sm-global--FontSize-title--lg:     2.812rem;  /* Large: 45px */
  --sm-global--FontSize-title--xl:     4.250rem;  /* Extralarge: 70px */

  --sm-global--FontSize-paragraph--3xs: 1.250rem;  /* Extra extra extrasmall: 20px */
  --sm-global--FontSize-paragraph--2xs: 1.375rem;  /* Extra extrasmall: 22px */
  --sm-global--FontSize-paragraph--xs:  1.875rem;  /* Extrasmall: 30px */
  --sm-global--FontSize-paragraph--sm:  2.250rem;  /* Small: 35px */
  --sm-global--FontSize-paragraph--md:  2.750rem;  /* Medium: 45px */
  --sm-global--FontSize-paragraph--lg:  4.250rem;  /* Large: 70px */

  --sm-global--FontSize-label--2xs: .625rem;  /* extra extrasmall: 110px */
  --sm-global--FontSize-label--xs:  .750rem;  /* Extrasmall: 12px */
  --sm-global--FontSize-label--sm:  .875rem;  /* Small: 14px */
  --sm-global--FontSize-label--md:  1rem;     /* Medium: 16px */
  --sm-global--FontSize-label--lg:  1.125rem; /* Large: 18px */
  --sm-global--FontSize-label--xl:  1.25rem;  /* Extralarge: 20px */


  /**
   * Text styles
   */

  --sm-global--Font-title-lg-semibold: var(--sm-global--FontWeight-semibold) var(--sm-global--FontSize-title--lg) var(--sm-global--FontFamily-default);
  --sm-global--Font-title-xl-semibold: var(--sm-global--FontWeight-semibold) var(--sm-global--FontSize-title--xl) var(--sm-global--FontFamily-default);

  --sm-global--Font-paragraph-sm-regular: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-paragraph--sm) var(--sm-global--FontFamily-default);
  --sm-global--Font-paragraph-md-regular: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-paragraph--md) var(--sm-global--FontFamily-default);
  --sm-global--Font-paragraph-lg-regular: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-paragraph--lg) var(--sm-global--FontFamily-default);

  --sm-global--Font-paragraph-3xs-medium: var(--sm-global--FontWeight-medium) var(--sm-global--FontSize-paragraph--3xs) var(--sm-global--FontFamily-default);
  --sm-global--Font-paragraph-xs-medium: var(--sm-global--FontWeight-medium) var(--sm-global--FontSize-paragraph--xs) var(--sm-global--FontFamily-default);
  --sm-global--Font-paragraph-sm-medium: var(--sm-global--FontWeight-medium) var(--sm-global--FontSize-paragraph--sm) var(--sm-global--FontFamily-default);

  --sm-global--Font-label-xs-regular: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--xs) var(--sm-global--FontFamily-default);
  --sm-global--Font-label-sm-regular: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--sm) var(--sm-global--FontFamily-default);
  --sm-global--Font-label-md-regular: var(--sm-global--FontWeight-regular) var(--sm-global--FontSize-label--md) var(--sm-global--FontFamily-default);

  --sm-global--Font-label-xl-medium: var(--sm-global--FontWeight-medium) var(--sm-global--FontSize-label--xl) var(--sm-global--FontFamily-default);

  --sm-global--Font-dynamic-sm-regular:
    var(--sm-global--FontWeight-regular)
    max(var(--sm-global--FontSize-paragraph--2xs), min(2.43vw, 2.19rem))
    var(--sm-global--FontFamily-default);
  --sm-global--Font-dynamic-md-regular:
    var(--sm-global--FontWeight-regular)
    max(1rem, min(12vmin, var(--sm-global--FontSize-paragraph--sm)))
    var(--sm-global--FontFamily-default);
  --sm-global--Font-dynamic-lg-regular:
    var(--sm-global--FontWeight-regular)
    max(2.19rem, min(4.86vw, 4.375rem))
    var(--sm-global--FontFamily-default);

  --sm-global--Font-dynamic-md-medium:
    var(--sm-global--FontWeight-medium)
    max(1rem, min(12vmin, var(--sm-global--FontSize-paragraph--sm)))
    var(--sm-global--FontFamily-default);
  --sm-global--Font-dynamic-lg-medium:
    var(--sm-global--FontWeight-medium)
    max(2.19rem, min(4.86vw, 4.375rem))
    var(--sm-global--FontFamily-default);
}
