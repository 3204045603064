/** ***************************************************************************
 * Supermatita utility classes
 *************************************************************************** */

/**
 * Hidden
 */

.sm-u-hidden {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  touch-events: none;
}
